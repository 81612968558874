<template>
  <div>
    <div class="order-table-wrapper" style="padding-top:0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <div class="e-tabs-wrapper">
                <div class="e-tabs-item " :class="{'active':daIndex==0}" @click="setDate(0)">今天
                </div>
                <div class="e-tabs-item " :class="{'active':daIndex==1}" @click="setDate(1)">昨天
                </div>
                <div class="e-tabs-item " :class="{'active':daIndex==2}" @click="setDate(2)">本月
                </div>
                <div class="e-tabs-item " :class="{'active':daIndex==3}" @click="setDate(3)">上月
                </div>
              </div>
              <span class="v-form-label">门店</span>
              <el-select size="small" clearable v-model="departmentCode" placeholder="选择门店" @change="getEmployeeList(departmentCode)">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id">
                </el-option>
              </el-select>
              <span class="v-form-label">员工</span>
              <el-select
                clearable
                size="small"
                v-model="employeeId"
                placeholder="选择员工"
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" style="margin-left: 28px" type="primary"
                           @click="requestSearch()">
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <el-table
            :data="list"
            stripe
            :header-cell-style="{background:'#fff','font-weight':'bold'}"
            style="width: 100%;" >
            <el-table-column prop="nickName" min-width="50%" label="员工" align="center">
            </el-table-column>
            <el-table-column prop="orderNum" min-width="50%" label="服务总客数" align="center">
            </el-table-column>
            <el-table-column prop="skuAmount" min-width="50%" label="卖品总业绩" align="center">
            </el-table-column>
            <el-table-column prop="packageAmount" min-width="50%" label="套餐总业绩" align="center">
            </el-table-column>
            <el-table-column prop="repayment" min-width="50%" label="办卡/充卡业绩" align="center">
            </el-table-column>
            <el-table-column prop="itemNum" min-width="50%" label="服务项目数量" align="center">
            </el-table-column>
            <el-table-column prop="itemAmount" min-width="50%" label="服务项目总业绩" align="center">
            </el-table-column>
            <el-table-column label="业绩明细" align="center">
              <el-table-column prop="itemCard" min-width="50%" label="项目划卡业绩" align="center">
              </el-table-column>
              <el-table-column prop="itemCash" min-width="50%" label="项目现金业绩" align="center">
              </el-table-column>
              <el-table-column prop="packageCard" min-width="50%" label="套餐划卡业绩" align="center">
              </el-table-column>
              <el-table-column prop="packageCash" min-width="50%" label="套餐现金业绩" align="center">
              </el-table-column>
              <el-table-column prop="skuCard" min-width="50%" label="商品划卡业绩" align="center">
              </el-table-column>
              <el-table-column prop="skuCash" min-width="50%" label="商品现金业绩" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="指定客分析" align="center">
              <el-table-column prop="nvOrderNum" min-width="50%" label="女客" align="center">
              </el-table-column>
              <el-table-column prop="nanOrderNum" min-width="50%" label="男客" align="center">
              </el-table-column>
              <el-table-column prop="city" min-width="50%" label="指定率" align="center">
              </el-table-column>
              <el-table-column prop="address" min-width="50%" label="同比上月" align="center">
              </el-table-column>
            </el-table-column>
          </el-table>
<!--          <div class="pagination">-->
<!--            <el-pagination-->
<!--              background-->
<!--              @current-change="handleCurrentChange"-->
<!--              :pager-count="7"-->
<!--              layout="total, prev, pager, next,jumper"-->
<!--              :total="pagination.count"-->
<!--              :page-count="pagination.pages"-->
<!--              :current-page="pagination.pageNumber"-->
<!--            ></el-pagination>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        tabIndex: -1,
        daIndex: 0,
        gradeId: '',
        storeList: [],
        userGradeList: [],
        cxDate: [],
        employeeList: [],
        departmentCode: '',
        employeeId:'',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
      }
    },
    async created() {
      this.getStoreList()
      this.getUserGrade()
      // this.requestSearch()
    },
    methods: {
      async getEmployeeList(departmentCode){
        let res = await this.$get("/admin/getEmployeeListByCode",{"departmentCode":departmentCode});
        this.employeeList = res.list;
      },
      async getUserGrade() {
        let res = await this.$get("/admin/getUserGradeList")
        if (res.code == 200) {
          this.userGradeList = res.list
        }
      },
      setDate(index) {
        this.daIndex = index
        this.cxDate=[]
      },
      switchTable(index, id) {
        this.gradeId = id
        this.tabIndex = index
      },
      async getStoreList() {
        let res = await this.$get("/admin/getStoreList")
        this.storeList = res;
      },


      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      async requestSearch(page) {
        if (!this.departmentCode){
          this.$message.error("请选择门店")
          return ;
        }
        if (!this.employeeId){
          this.$message.error("请选择员工")
          return ;
        }
        let staDate = '';
        let endDate = '';
        let dateType = "";
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0]
          endDate = this.cxDate[1]
        } else {
          dateType = this.daIndex;
        }
        let data = {
          staDate: staDate,
          endDate: endDate,
          gradeId: this.gradeId,
          employeeId: this.employeeId,
          departmentCode: this.departmentCode,
          isDel: '0',
          dateType:dateType,
        }
        let res = await this.$get("/admin/getEmployeeForPage", data)
        this.list = res.dataSet.list;
        // this.pagination = {
        //   count: res.dataSet.count,
        //   pageNumber: res.dataSet.pageNumber,
        //   pageSize: res.dataSet.pageSize,
        //   pages: res.dataSet.pages
        // }
      },
    },
  }

</script>

<style lang="scss" scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .box-card {
    width: 18%;
    margin-right: 20px;
    display: inline-block;
  }

  .box-cards {
    width: 35%;
    margin-right: 20px;
  }

  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }

  }

  .text-items {
    width: 50%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }
    .item-h4 {
      height: 20px;
      font-size: 12px;
    }
  }
</style>
